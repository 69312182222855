import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from '../constants'

export const styles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '50px',
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      height: '85vh',
      maxWidth: '500px',
      margin: '0 auto',
    },
  },
  logo: {
    maxWidth: '27px',
    position: 'absolute',
    top: '60px',
    right: '20px',
  },
  goBack: {
    position: 'absolute',
    top: '67px',
    left: '20px',
  },
  content: {
    flex: '1 auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '0 40px',
  },
  title: {
    fontSize: '28px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    lineHeight: '100%',
  },
  mainWrapper: {
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    textAlign: 'center',
  },
  logoText: {
    maxWidth: '136px',
    paddingTop: '20px',
  },
  button: {
    backgroundColor: '#BFD3D0',
    borderColor: '#BFD3D0',
    border: '1px solid #000000 !important',
    height: '40px',
    width: '200px',
  },
  buttonText: {
    color: '#000000 !important',
    fontSize: '13px',
  },
  text: {
    fontWeight: 400,
    fontSize: '16px',
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      maxWidth: '300px',
    },
  },
  media: {
    maxWidth: '230px',
    maxHeight: '230px',
    width: '100%',
    margin: '10px 0',
    [`@media (min-width: ${BREAKPOINTS.sm}px)`]: {
      maxWidth: '300px',
      maxHeight: '300px',
    },
  },
  link: {
    color: COLORS.black,
  },
  list: {
    textAlign: 'left',
    paddingInlineStart: '24px',
    marginTop: 0,
    fontFamily: FONTS.Graphik.GraphikRegular,
    lineHeight: '21px',
  },
  listItem: {
    paddingTop: '10px',
  },
  inlineIcon: {
    marginLeft: '8px',
    lineHeight: '21px',
  },
  textWithIcons: {
    display: 'flex',
    alignItems: 'center',
  },
  extensionLogo: {
    display: 'inline-block',
    width: '25px',
    height: '25px',
    marginBottom: '-5px',
    border: '0.1px solid #ABABAB',
    borderRadius: '3px',
  },
}))
